<template>
  <div class="flex-box app-content">
    <div class="header-container">
      <div class="icon-sidebar" @click="showSidebar=!showSidebar">
        <span class="iconfont icon icon-ota-liebiao" ></span>
      </div>
      <div class="jaka-logo"></div>
      <div class="head-options">
        <span class="userInfo">{{userInfo.companyName}}</span>
        <span class="iconfont icon icon-ota-yingyongguanli" @click="jumpUrl"></span>
        <span class="iconfont icon icon-ota-guangbo"></span>
        <el-dropdown>
          <span class="iconfont icon icon-ota-icon_zhanghao"></span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item class="dropdown-item" @click="dialogPassword=true">修改密码</el-dropdown-item>
              <el-dropdown-item class="dropdown-item" @click="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <!-- <div class="slogan">用  机  器  人  解  放  双  手</div> -->
    </div>
    <div class="main-container">
      <sidebar v-if="showSidebar" />
      <app-main/>
    </div>
    <el-dialog title="修改密码" v-model="dialogPassword" :modal="true" :append-to-body="true">
      <el-form ref="form" class="dialog-form" label-width="100px">
        <el-form-item label="新密码：">
            <el-input placeholder="请输入新密码" v-model="newPassword1" show-password>
            </el-input>
        </el-form-item>
        <el-form-item label="确认密码：">
            <el-input placeholder="请再次输入新密码" v-model="newPassword2" show-password>
            </el-input>
        </el-form-item>
        <el-form-item label="" style="color: #D80C1E;">
          密码长度8-64位，须同时包含大、小写字母和数字
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="mini" @click="setPassword">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import AppMain from './components/AppMain.vue'
import Sidebar from './components/sidebar.vue'
import { request } from "@/utils/request";
import { ElMessage } from "element-plus";

export default {
  components: {
    Sidebar,
    AppMain
  },
  data () {
    return {
      dialogPassword: false,
      newPassword1: '',
      newPassword2: '',
      showSidebar: true,
      userInfo: {}
    }
  },
  created() {
    try {
      const userInfo = window.localStorage.getItem('userInfo')
      if (userInfo) {
        this.userInfo = JSON.parse(userInfo) || {}
      }
    } catch (error) {
      console.error(error)
    }
  },
  watch: {
    '$route.path': {
      handler: function (val) {
        if (document.documentElement.clientWidth < 500) {
          this.showSidebar = false
        }
        console.log('$route.path =>', val)
      },
      immediate: true
    }
  },
  methods: {
    loginOut() {
      window.localStorage.removeItem('token')
      this.$router.push({ path: '/login' })
    },
    async setPassword() {
      try {
        if (!this.newPassword1 || !this.newPassword2) {
          ElMessage({
            showClose: true,
            type: "warning",
            message: "密码不能为空"
          })
          return
        } else if (this.newPassword1 !== this.newPassword2) {
          ElMessage({
            showClose: true,
            type: "warning",
            message: "两次输入的密码不一致"
          })
          return
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*?]{8,64}$/.test(this.newPassword1)) {
          ElMessage({
            showClose: true,
            type: "warning",
            message: "密码不符合要求"
          })
          return
        }
        await request('setPassword', {
          password: this.newPassword1
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "密码修改成功"
        })
        this.newPassword1 = ''
        this.newPassword2 = ''
        this.dialogPassword = false
      } catch (error) {
        console.error(error)
      }
    },
    jumpUrl() {
      this.$router.push({ path: '/index/dashboard' })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/mixin.scss";
  @import "@/styles/variables.scss";
  @import "@/styles/index.scss";
  .jaka-logo {
    background-image: url('../assets/jaka_logo_white.png');
    background-size: 100%;
    background-repeat: none;
    background-position-y: center;
    width: 130px;
    height: 32px;
    // background-color: #eee;
    position: relative;
    top: 15px;
    left: 15px;
    float: left;
  }
  .header-container {
    height: 60px;
    width: 100%;
    background-color: #D80C1E
  }
  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;

    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }
  .head-options {
    // width: 385px;
    display: flex;
    height: 40px;
    line-height: 40px;
    position: relative;
    right: 20px;
    top: 15px;
    float: right;
    text-align: right;
    // padding: 5px;
  }
  .head-options span:hover {
    background-color: rgba(255, 255, 255, 0.25);
    cursor: pointer;
  }
  .userInfo{
    font-family: "iconfont" !important;
    font-size: 20px;
    color: #fff;
    margin-right: 10px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slogan {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    top: 10px;
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 0.5;
  }
  .dropdown-item {
    min-width: 120px;
  }

  .icon-sidebar {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 5000;
    display: none;

  }
  @media (max-width: 500px) {
    .icon-sidebar {
      display: inline-block;
    }
    .slogan {
      display: none;
    }
  }
</style>
